<template>
  <b-button
    v-b-toggle="id"
    :size="size"
    variant="link"
    :class="['accordion-toggle', { 'is-modest': modest }]"
    role="tab"
  >
    <slot/> <b-icon v-if="!noIcon" class="toggle-icon" :icon="icon" scale="1.25"/>
  </b-button>
</template>

<script>
export default {
  name: 'AccordionToggle',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'arrow-dropdown'
    },
    modest: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$accordion-toggle-color: inherit !default;
$accordion-toggle-separator: $border-width $border-style $border-color !default;
$accordion-toggle-hover-color: $primary !default;
$accordion-toggle-active-color: $accordion-toggle-hover-color !default;

$accordion-toggle-icon-transition-in: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 400ms !default;
$accordion-toggle-icon-transition-out: all cubic-bezier(0.35, 0.05, 0.4, 1.5) 200ms !default;

.accordion {
  .accordion-toggle {
    @include reset-leaf();
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    box-shadow: none;
    color: $accordion-toggle-color;
    text-align: left;
    text-decoration: none;

    .toggle-icon {
      transition: $accordion-toggle-icon-transition-out;
    }

    &:hover {
      color: $accordion-toggle-hover-color;
    }

    &[aria-expanded="true"] {
      color: $accordion-toggle-active-color;

      .toggle-icon {
        transform: scaleY(-1);
        transition: $accordion-toggle-icon-transition-in;
      }
    }

    &.is-modest {
      padding: 0;
    }
  }

  .accordion-content + .accordion-toggle {
    border-top: $accordion-toggle-separator;
  }
}
</style>
