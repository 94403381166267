<template>
  <b-collapse
    :id="id"
    :accordion="group"
    :visible="visible"
    :class="['accordion-content', { 'is-modest': modest }]"
    role="tabpanel"
  >
    <div>
      <slot/>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: 'AccordionToggle',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    group: {
      type: String,
      default: 'accordion'
    },
    visible: {
      type: Boolean,
      default: false
    },
    modest: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$accordion-content-padding-y: $component-padding-y !default;
$accordion-content-padding-x: 0 !default;
$accordion-content-separator: $border-width $border-style $border-color !default;

.accordion-content {
  border-top: $accordion-content-separator;

  > div {
    padding: $accordion-content-padding-y $accordion-content-padding-x;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.is-modest {
    border-top: 0 none;

    > div  {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
