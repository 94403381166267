<template>
  <div class="accordion" role="tablist">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Accordion'
}
</script>

<style lang="scss">
.accordion {}
</style>
